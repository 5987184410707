import React from "react";
import {
  Box,
  styled,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  Accordion,
  AccordionSummary as AccSum,
  AccordionDetails,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import BackdropComponent from "../../components/BackDrop";
import { makeStyles } from "@mui/styles";
import { UTCtoLocal } from "../../utils/Tools";

const useStyles = makeStyles((theme) => ({
  containerPrincipal: {
    border: ".8px solid",
    borderRadius: 4,
    borderColor: theme.palette.secondary.main60,
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  containerConfig: {
    marginTop: 10,
    width: "80%",
    borderLeft: `1.8px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0",
  width: "100%",
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "2px solid red",
  borderBottomColor: theme.palette.secondary.main60,
  padding: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "99%",
  borderBottom: ".8px solid",
  borderBottomColor: theme.palette.secondary.main60,
  justifyContent: "space-between",
  marginTop: 8,
  marginLeft: 5,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "35%",
  marginBottom: 3,
  marginTop: 4,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "65%",
  overflow: "auto",
  marginTop: 4,
  marginBottom: 3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const FileInformationTab = () => {
  const fileState = useSelector((state) => state.File);

  if(fileState.loading && (fileState.items == null || fileState.items == undefined)){
    return <BackdropComponent loading={fileState.loading} />

  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <BackdropComponent loading={fileState.loading} />
      <RootStyle>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Typography variant="titulo">Propiedades de la solicitud</Typography>
        </Box>
        <InfomacionExpediente />

        <Box sx={{ mt: 3 }}>
          <Typography variant="titulo">
            Configuración de la solicitud
          </Typography>
        </Box>
        <ConfiguracionExpediente />
      </RootStyle>
    </LocalizationProvider>
  );
};

export const FileInformationFirmantes = () => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Lista de revisores
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RevisionDocumento />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Lista de firmantes
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FirmaDocumentos />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Participantes que solo revisaran el documento
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DocumentosComplementarios />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const ConfiguracionExpediente = () => {
  const fileState = useSelector((state) => state);
  const classes = useStyles();

  const typeSignFile = useSelector(
    (state) => state.File?.item?.SignatureOptions?.SignatureType
  );
  let rol =
    fileState?.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
      ?.Name;

  return (
    <>
      {rol === "Usuario Autoconsumo" ||
      rol === "Administrador" ||
      rol === "Agente" ? (
        <Box className={classes.containerConfig}>
          <Box sx={{ backgroundColor: "#F5F5F5", p: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{ mb: 1, display: "flex", alignItems: "center" }}
            >
              <b>Tipo de firma</b>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                sx={{ mb: 1, display: "flex", alignItems: "center" }}
              >
                {typeSignFile === null
                  ? "Mixta"
                  : fileState?.File?.item?.SignatureOptions?.SignatureType ===
                    "Simple"
                  ? "Firma Autógrafa en Mensaje de Datos"
                  : "Firma Electrónica Avanzada"}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box className={classes.containerConfig}>
        <Box sx={{ backgroundColor: "#F5F5F5", p: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{ mb: 1, display: "flex", alignItems: "center" }}
          >
            <b>Propiedades de la solicitud</b>
          </Typography>
          <Box>
            <Box sx={{ mt: 0.5 }}>
              <Typography variant="subtitle1">
                Geolocalización: Se recolectarán las coordenadas del dispositivo
                en el proceso de la firma del documento.
              </Typography>
            </Box>
            {typeSignFile === null ? null : fileState.File?.item
                ?.SignatureOptions?.SignatureType === "Simple" ? (
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="subtitle1">
                  Contraseña de un solo uso (OTP): Se agregará un código
                  numérico que complementará la atribución de un firmado
                  electrónico
                </Typography>
              </Box>
            ) : (
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="subtitle1">
                  Contraseña de un solo uso (OTP): Se agregará un código
                  numérico que complementará la atribución de un firmado
                  electrónico.
                </Typography>
              </Box>
            )}
            <Box sx={{ mt: 0.5 }}>
              <Typography variant="subtitle1">
                NOM-151: Constancia de conservación de mensaje de datos.
              </Typography>
            </Box>
            {typeSignFile === null ? null : fileState.File?.item
                ?.SignatureOptions?.VideoRecording ? (
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="subtitle1">
                  Videograbación: Por medio de un video se grabará la aceptación
                  de términos y condiciones.
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box className={classes.containerConfig}>
        <Box sx={{ backgroundColor: "#F5F5F5", p: 1 }}>
          
          <Typography
            variant="subtitle1"
            sx={{ mb: 1, display: "flex", alignItems: "center" }}
          >
            <b>Notificaciones</b>
          </Typography>
          <Box>
            {fileState.File.item.NotificationOptions.NotifyAllUserCheck ===
            true ? (
              <Typography sx={{ mt: 0.5 }} variant="subtitle1">
                Notificar eventos a todos los revisores.
              </Typography>
            ) : null}
            {fileState.File.item.NotificationOptions.NotifyAllUserSign ===
            true ? (
              <Typography sx={{ mt: 0.5 }} variant="subtitle1">
                Notificar eventos a todos los firmantes.
              </Typography>
            ) : null}
            {console.log("File.fileState?.item", fileState.File.item)}
            {fileState.File.item.NotificationOptions.NotifySignedDocuments ===
            false ? null : (
              <Typography sx={{ mt: 0.5 }} variant="subtitle1">
                Notificación de documentos firmados.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const InfomacionExpediente = () => {
  const classes = useStyles();
  const fileState = useSelector((state) => state?.File);
  const statusCancel = useSelector(
    (state) => state.File?.item?.FileData?.Validation
  );

  console.log("statusCancel", statusCancel);

  const comentarios =
    statusCancel?.Approved === false
      ? statusCancel?.Results[0]?.Message.substring(
          statusCancel?.Results[0]?.Message.indexOf("comentarios:") + 12
        )
      : null;

  return (
    <>
      <Box className={classes.containerPrincipal}>
        <BoxRexponsiveText
          titulo={<b>Nombre de la solicitud</b>}
          texto={fileState?.item?.Name}
        />
        <BoxRexponsiveText
          titulo={<b>Folio</b>}
          texto={fileState?.item?.FileData?.Folio}
        />
        <BoxRexponsiveText
          titulo={<b>Tipo de la solicitud</b>}
          texto={fileState?.item?.FileData.Product}
        />
        <BoxRexponsiveText
          titulo={<b>Vigencia del documento a firmar</b>}
          texto={UTCtoLocal(fileState?.item?.Validity, false)}
        />
        <BoxRexponsiveText
          titulo={<b>Asignado actualmente</b>}
          texto={fileState?.item?.FileData?.CurrentUser ?? "---"}
        />
        {fileState?.item?.MetadataList?.map((item, index) =>
          item.Name !== "Observaciones" ||
          item.Name !== "Nombre del solicitante" ? null : (
            <BoxRexponsiveText
              key={index}
              titulo={<b>{item?.Name}</b>}
              texto={item.Value}
            />
          )
        )}
        {console.log(
          "fileState?.item?.FileData?.Status",
          fileState?.item?.FileData?.Status
        )}
        <BoxRexponsiveText
          titulo={<b>Estatus</b>}
          texto={
            fileState?.item?.FileData?.Status === "Carga de prerrequisitos"
              ? "Carga de requisitos previos"
              : fileState?.item?.FileData?.Status ===
                "Validación de prerrequisitos"
              ? "Validación de requisitos previos"
              : fileState?.item?.FileData?.Status
          }
        />
        {statusCancel?.Approved === false ? (
          <BoxRexponsiveText
            titulo={<b>Motivo del Rechazo:</b>}
            texto={comentarios}
          />
        ) : null}
      </Box>
    </>
  );
};

const RevisionDocumento = () => {
  const fileState = useSelector((state) => state.File);
  return <FilesOrderTable data={fileState?.item?.Documents} accion="Check" />;
};

const FirmaDocumentos = () => {
  const fileState = useSelector((state) => state.File);
  return <FilesOrderTable data={fileState?.item?.Documents} accion="Sign" />;
};

const DocumentosComplementarios = () => {
  const fileState = useSelector((state) => state.File);
  return (
    <FilesOrderTable data={fileState?.item?.Documents} accion="OnlyView" />
  );
};

const FilesOrderTable = ({ data, accion }) => {
  const renderSignList = () => {
    const signListDocs = data?.filter(
      (doc) =>
        doc?.Participants?.filter((user) => user[accion] === true).length > 0
    );

    if (signListDocs?.length === 0) {
      return null;
    }

    return <TableBodyDocuments documents={signListDocs} type={accion} />;
  };

  return (
    <TableContainer component={Paper} elevation={0} sx={{ mt: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead
          sx={{
            outline: "1px solid grey",
            border: "1px solid grey",
            borderRadius: 0,
          }}
        >
          <TableRow>
            <TableCell>Nombre del documento</TableCell>
            <TableCell>Responsable</TableCell>
            <TableCell>
              Fecha límite para{" "}
              {accion === "Sing"
                ? "firmar"
                : accion === "Check"
                ? "revisión"
                : "documento"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderSignList()}</TableBody>
      </Table>
    </TableContainer>
  );
};

const TableBodyDocuments = ({ documents, type }) => {
  return (
    <>
      {documents?.map((item) =>
        item?.Participants?.map((parti, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {item?.FileName}
            </TableCell>
            <TableCell component="th" scope="row">
              {parti?.FullName}
            </TableCell>
            <TableCell component="th" scope="row">
              {type === "Check"
                ? parti?.CheckDeadlineFormat
                : type === "Sign"
                ? parti?.CheckDeadlineFormat
                : null}
            </TableCell>
          </TableRow>
        ))
      )}
    </>
  );
};

const BoxRexponsiveText = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="subtitle1">{props.titulo}</Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="subtitle1">{props.texto}</Typography>
      </BoxTexto>
    </BoxContainer>
  );
};
