import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncEffect } from "use-async-effect";
import { Grid, Button, Box, Typography, CircularProgress, Dialog } from "@mui/material";
import VisorPDF from "../../components/Viewer/VisorPDF";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SupplementaryDocument from "./ComplementaryDocument.js";
import ListDocuments from "./ListDocuments.js";
import RevisarOtroMomento from "./RevisarOtroMomento.js";
import {
  getDocument64,
  getFile,
  resetDocument64,
  resetDocumentEnlace,
  setDocument,
} from "../../store/actions/ExpedienteActions.js";
import {
  acceptanceRejection,
  notificationPINStageFile,
} from "../../store/actions";
import { FileFlowService } from "../../services/FileFlowService";
import { useNavigate } from "react-router-dom";
import { constUrlVisor, useWindowDimensions } from "../../utils";
import { CustomAlertHelpDialog } from "../../components/CustomAlertHelpDialog";
import HelpVideoEdicionDoc from '../../assets/EdicionDocumentos.mp4';
import HelpPages from "../../components/HelpPages.jsx";
import { MassiveFlowDocumentViewer } from "../MassiveSignature/MassiveFlowDocumentViewer";
import ConfigFirmDrag from "../new-file/ConfigFirmDrag.jsx";
import { FileService } from "../../services/FileService.js";
import BackdropComponent from "../../components/BackDrop.js";
import { Alert } from "../../components/Alert.jsx";
import { ToastNotification } from "../../components/toastNotification.js";
import { LoadingButton } from "@mui/lab";

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  let atob = require("atob");
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return wordUrl;
};

export default function ExpedientFlow({ setValue, isActiveMassiveSignature }) {
  const { height, width } = useWindowDimensions();
  const userLogoutClose = true;
  // const location = useLocation();
  // const ruta = location.pathname.includes("/inbox/file-information-search");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blobUrl, setBlobUrl] = useState("");
  const [urlImagen, setUrlImagen] = useState();
  const [viewData, setViewData] = useState(false);
  const [validationUserVersion, setValidationUserVersion] = useState(false);
  const [openAlertButton, setOpenAlertButton] = useState(false);
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const documentIdType = useSelector(
    (state) => state.File?.item?.EnviromentVars.DocumentTypeExpediente
  );
  const [currentDocumentPreview, setCurrentDocumentPreview] = useState(null);
  const [loadDocument, setLoadDocument] = useState(null);
  const fileState = useSelector((state) => state.File);
  const stateFlowSignerZone = useSelector((state) => state.File?.item?.FileData?.Stage?.WorkflowInstance?.CurrentStatus);

  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.FlowState
  );
  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData
  );
  const documentStatus = useSelector(
    (state) => state.File?.document?.DocumentData?.Validation?.Status
  );
  const documentSelected = useSelector(
    (state) => state.File?.document
  );
  const documentValidationApproved = useSelector(
    (state) => state.File?.document?.DocumentData?.Validation?.Approved
  );
  const rolUser = userData?.SecurityLoginData?.Roles[0]?.Name;
  const participantCurso = userData?.Username === fileState?.item?.FileData?.CurrentUser;

  const statusAdminParticipant = fileState.item?.Participants?.filter(
    (use) => rolUser === 'Administrador' ? use?.UserId === userData?.Id : false
  );

  const expedienteDigital = useSelector((state) => fileState?.item?.FileData?.Actors[0]?.DocumentalGroups?.find(item => item.Code === documentIdType?.DocumentalGroup?.Code)?.Documents.find((item) => item.DocumentType_Id === documentIdType?.Id));

  const statusParticipantAprobed = fileState.item?.Participants?.filter(
    (use) => use?.Reviewer === true
  );
  const userParticipante = fileState.item?.Participants?.filter(
    (use) => use?.UserId === userData?.Id
  );
  const userName = `${userData?.Name} ${userData?.FatherLastName ?? ''} ${userData?.MotherLastName ?? ''}`;

  //Validacion participante existe dentro de los participantes dentro del flujo
  const participant = fileState?.item?.Participants?.filter(
    (part) => (part.UserId === userData?.Id) && (part.Check === true || part.Reviewer === true)
  );
  const stageFileDocumentUsers = useSelector(
    (state) => state.File?.item?.Documents
  );

  const stageFirmas = () => {
    switch (stageFileFlow) {
      case "Firma de documentos":
        return true;
      case "Notificacion De Asignacion de Firma":
        return true;
      case "Asignando solicitud de firma":
        return true;
      case "OTP Evidencias firma de documentos":
        return true;
      case "Evidencias firma de documentos":
        return true;
      case "Validación de evidencias":
        return true;
      default:
        break;
    }
  };

  const traerCodigoQR = async () => {
    const fileService = FileService.getInstance();
    const comentData = await fileService.getFileQR({
      idFile: fileState?.item?.FileData?.Id,
      token: sessionStorage.token,
    });
    const url = window.URL.createObjectURL(new Blob([comentData.data.data]));
    setUrlImagen(url);
  }

  useEffect(() => {
    if (stageFirmas() === true && fileState?.item?.FileData?.Id && participantCurso) {
      traerCodigoQR()
    }
  }, [fileState?.item?.FileData?.Id, stageFirmas()])

  useEffect(() => {
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
      if (stageFileFlow === 'Revisión de documentos' || stageFileFlow === 'Revisión de Administrador') {
        setOpenAlertButton(true);
      }
    }
  }, [])


  useAsyncEffect(async () => {
    console.log('', )
    setBlobUrl(null);
    dispatch(resetDocumentEnlace());
    dispatch(resetDocument64());
    const fileService = FileService.getInstance();
    if (stageFirmas() === true && expedienteDigital !== undefined
      && expedienteDigital !== null) {
      setLoadDocument(true)
      const comentData = await fileService.getDownloadDocument({
        document_Id: expedienteDigital?.Id,
        ConvertSfdt: false,
      });
      dispatch(setDocument(expedienteDigital));
      dispatch(
        getDocument64({
          document_Id: expedienteDigital?.Id,
          ConvertToWord: false,
        })
      );
      traerDocumentos(comentData);
    } else if (stageFileFlow === 'Carga a Docflow') {
      const docGroup = fileState.item?.FileData.Actors[0].DocumentalGroups.filter((doc) => doc?.Code === documentIdType?.DocumentalGroup?.Code);
      const docSignExp = docGroup[0]?.Documents.filter((id) => id.DocumentType_Id === documentIdType?.Id);
      setLoadDocument(true)
      const comentData = await fileService.getDownloadDocument({
        document_Id: docSignExp[0]?.Id,
        ConvertSfdt: false,
      });

      dispatch(setDocument(docSignExp[0]));
      dispatch(
        getDocument64({
          document_Id: docSignExp[0]?.Id,
          ConvertToWord: false,
        })
      );
      traerDocumentos(comentData);
    } else {
      let documentConsultId = fileState.document?.DocumentData === undefined
        || fileState.document?.DocumentData === null ?
        fileState.document?.Id : fileState.document?.DocumentData?.Id
      if (documentConsultId > 0) {
        setLoadDocument(true)
        setCurrentDocumentPreview(null)

        const comentData = await fileService.getDownloadDocument({
          document_Id: documentConsultId,
          ConvertSfdt: false,
        });
        dispatch(
          getDocument64({
            document_Id: documentConsultId,
            ConvertToWord: false,
          })
        );
        traerDocumentos(comentData);
      }
    }
  }, [fileState.item, fileState?.document?.DocumentData?.Id, stageFirmas()]);

  useAsyncEffect(async () => {
    setBlobUrl(null);
    if (currentDocumentPreview !== undefined && currentDocumentPreview !== null && currentDocumentPreview !== "") {
      setLoadDocument(true)
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument({
        document_Id: currentDocumentPreview,
        ConvertSfdt: false,
      });
      dispatch(
        getDocument64({
          document_Id: currentDocumentPreview,
          ConvertToWord: false,
        })
      );
      traerDocumentos(comentData);
    }
  }, [currentDocumentPreview]);

  const traerDocumentos = (comentData) => {
    console.log('comentData', comentData)
     if (comentData.Body) {
       let extension = comentData.Body.FileName.split(".").length > 1 ? comentData.Body.FileName.split(".")[1] : "pdf"
       if (extension === "docx") {
         const docConvertido = convertWordB64ToPdfB64(
           comentData?.Body?.B64Content
         );
         docConvertido.then((value) => {
           setBlobUrl(value);
           setLoadDocument(null)
         });
       } else {
         const blob = B64toBlob(comentData?.Body?.B64Content, "application/pdf");
         setBlobUrl(URL.createObjectURL(blob));
         setLoadDocument(null)
       }
     }
   };

  const stagesDocumentButtons = () => {
     switch (stageFileFlow) {
      case "Nuevo expediente":
        return null;
      case "Revisión de documentos":
        return participant?.length > 0 ?
          (<Grid container>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  minWidth: 300,
                }}
              >
                <Button
                  fullWidth
                  size="medium"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenEditorClick()}
                >
                  <Typography sx={{ letterSpacing: 1.2, fontWeight: 550 }}>
                    {participant[0]?.Reviewer ? 'Revisar cambios' : 'Verificar'}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid >) : null
      case "Revisión de Administrador":
        return participant?.length > 0 ? (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                p: 1,
                display: "flex",
                justifyItems: "center",
                width: 354,
                justifyContent: "center",
              }}
            >
              <Button
                fullWidth
                size="medium"
                variant="outlined"
                color="primary"
                onClick={() => handleOpenEditorClick()}
              >
                <Typography sx={{ letterSpacing: 1.2, fontWeight: 550 }}>
                  Verificar cambios
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : null;
      case "Asignando solicitud de revisión":
        return null;
      case "Firma de documentos":
        return participantCurso === false ? null : <ButtonFirma setValue={setValue} fileState={fileState} isActiveMassiveSignature={isActiveMassiveSignature} />;
      case "Notificacion De Asignacion de Firma":
        return null;
      case "Asignando solicitud de firma":
        return null;
      case "Validación de evidencias":
        return null;
      case "OTP Evidencias firma de documentos":
        return participantCurso === false ? null : <ButtonFirma setValue={setValue} fileState={fileState} isActiveMassiveSignature={isActiveMassiveSignature} />;
      case "Evidencias firma de documentos":
        return participantCurso === false ? null : <ButtonFirma setValue={setValue} fileState={fileState} isActiveMassiveSignature={isActiveMassiveSignature} />;
      case "Finalizado":
        return null;
      case 'Finalizado Cancelado':
        return null;
      case "Carga a Docflow":
        return null;
      default:
        break;
    } 
  };

  const handleOpenEditorClick = () => {
    let filterDocCurrent = stageFileDocumentUsers[0]?.participantStatusCheck?.find(item => item.FullName === userName)
    let colorUser = filterDocCurrent?.Color?.replace("#", "") //document?.document?.DocumentData

    let wihe = "width=" + width + ",height=" + height;
    let win = window.open(
      `${constUrlVisor.ambient}?docId=${fileState?.document?.DocumentData?.Id
      }&rol=${participant ? (participant[0]?.Reviewer ? "Admin" : "User") : ''
      }&fileId=${fileState?.item?.FileData?.Id
      }&sectionId=${fileState?.document?.DocumentData?.Sections[0]?.Section_Id
      }&documentSectionId=${fileState?.document?.DocumentData?.Sections[0]?.Id
      }&docName=${fileState?.document?.DocumentData?.Name
      }&user=${userName}&token=${sessionStorage.token
      }&colorUser=${colorUser}`,
      "_blank",
      "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
    );
    let pollTimer = window.setInterval(function () {
      if (win.closed !== false) {
        window.clearInterval(pollTimer);
        redirectVentana()
      }
    }, 80);
  };

  const redirectVentana = async () => {
    if (statusParticipantAprobed[0].UserId === userData.Id) {
      dispatch(getFile({ FileId: fileState?.item?.FileData?.Id }));
      return setValidationUserVersion(true);
    } else {
      if (rolUser === "Operador" && userLogoutClose === true) {
        sessionStorage.clear();
        return navigate("/auth/login");
      } else {
        return navigate("/inbox/files");
      }
    }
  }

  useEffect(() => {
    if (fileState.item !== null && stateFlowSignerZone === "Firmado por zonas" && validationUserVersion === true) {
      setValidationUserVersion(false)
      Alert({
        icon: "warning",
        title: 'Se detectaron cambios en el documento, verifica que las zonas de firmado estén alineadas correctamente.',
        okbtntext: "Aceptar",
      })
      return navigate("/inbox/file-information");
    } else if (fileState.item !== null && stateFlowSignerZone !== "Firmado por zonas" && validationUserVersion === true) {
      setValidationUserVersion(false);
      if (rolUser === "Operador" && userLogoutClose === true) {
        sessionStorage.clear();
        return navigate("/auth/login");
      } else {
        return navigate("/inbox/files");
      }
    }
  }, [validationUserVersion, fileState.item])


  console.log('blobUrl', blobUrl)

  return (
    <Fragment>
      <BackdropComponent loading={fileState.loading} />
      {stageFileFlow === 'Revisión de documentos' ?
        <HelpPages
          Body={
            <Box sx={{ mt: 2 }}>
              <video
                width={"100%"}
                className="HelpVideo"
                src={HelpVideoEdicionDoc}
                autoPlay
                loop
                muted
              />
            </Box>
          }
        />
        : null}
      <Grid container justifyContent={'center'}>
       {stageFirmas() === true || stageFileFlow === 'Carga a Docflow' || stageFileFlow === 'Finalizado cancelado' ? null : (
          stateFlowSignerZone === "Firmado por zonas" && statusParticipantAprobed[0].UserId === userData.Id ? (
            <Grid item lg={10} >
              {console.log('PROCESODEREVISION 1')}
              <ConfigFirmDrag redEdition={true} />
            </Grid>
          ) :
            <Grid item xs={12} sm={3}>
              <Grid item xs={12} sm={12}>
                {console.log('PROCESODEREVISION LISTA')}
                <ListDocuments setValue={setValue} currentDocumentPreview={currentDocumentPreview} setCurrentDocumentPreview={setCurrentDocumentPreview} />
                <SupplementaryDocument document={fileState} />
              </Grid>
            </Grid>
        )}
        {stageFirmas() === true ? (
          <Grid sx={12} md={12} lg={12}>
            <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
              <Typography variant="h6" sx={{ fontWeight: 500, ml: 2 }}>
                Documento a firmar
              </Typography>
              <StageFirma 
                participantCurso={participantCurso}
                documentValidationApproved={documentValidationApproved}
                documentStatus={documentStatus}
                setValue={setValue}
                stagesDocumentButtons={stagesDocumentButtons}
                blobUrl={blobUrl}
                urlImagen={urlImagen}
                viewData={viewData}
                setViewData={setViewData}
                isActiveMassiveSignature={isActiveMassiveSignature}
              />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <StageFirma
                participantCurso={participantCurso}
                documentValidationApproved={documentValidationApproved}
                documentStatus={documentStatus}
                setValue={setValue}
                stagesDocumentButtons={stagesDocumentButtons}
                blobUrl={blobUrl}
                urlImagen={urlImagen}
                viewData={viewData}
                setViewData={setViewData}
                isActiveMassiveSignature={isActiveMassiveSignature}
              />
            </Box>
          </Grid>
        ) :
          stateFlowSignerZone === "Firmado por zonas" && statusParticipantAprobed[0].UserId === userData.Id ? null :
            (
              <Grid container item xs={12} sm={9}>
                <Grid container justifyContent={{ xs: 'center', lg: 'right' }} item xs={12} md={12} sm={12}>
                  <Box>
                    {console.log('PROCESODEREVISION 4')}
                    {fileState?.document?.OnlyView === true ? null :
                      !documentValidationApproved && documentStatus === "Firmado" ? (
                        <ButtonAceptarRechazoFirma
                          setValue={setValue}
                          Id={fileState.document?.DocumentData?.Id}
                        />
                      ) : (
                        stagesDocumentButtons()
                      )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ border: "1px solid #E4E4E4", p: 2, mt: 2, borderRadius: 2 }}
                >
                  {isActiveMassiveSignature === true ?
                    <Grid item>
                      <MassiveFlowDocumentViewer />
                    </Grid>
                    : <Grid item>
                      <Box sx={{ mt: 2 }}>
                        {blobUrl ? (
                          <VisorPDF
                            pdfUrl={blobUrl ?? ""}
                            pdfName={fileState?.document_64?.FileName ?? ""}
                          />
                        ) : loadDocument === true ? (
                          <Spinner />
                        ) : <Box>Selecciona un documento para visualizar</Box>}
                      </Box>
                    </Grid>
                  }
                </Grid>
              </Grid>
            )}
      </Grid >

      <Dialog
        maxWidth="sm"
        fullWidth
        open={openAlertButton}
        onClose={() => setOpenAlertButton(false)}
      >
        <Box sx={{ p: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ErrorOutlineIcon fontSize="medium" sx={{ mr: 1, color: 'primary.main60' }} />
            <Typography variant="titulo">
              Recomendación
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "justify" }}>
            <Typography variant="subtitle" align="justify" sx={{ mb: 3 }}>
              Estimado colaborador
              <br />
              <br />
              La edición de tu archivo puede ser desde tu dispositivo
              móvil, sin embargo te sugerimos que la realices desde
              tu computadora para una mejor experiencia.
            </Typography>
          </Box>
          <Box
            sx={{ mx: "auto" }} F
          >
            <Button
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                setOpenAlertButton(false)
              }}
              sx={{ mx: "auto", display: "flex", mt: 1 }}
            >
              Cerrar
            </Button>
          </Box>
        </Box>
      </Dialog>

      <CustomAlertHelpDialog
        body={
          <iframe
            id="editorTexto"
            title="Editor de texto"
            style={{ width: "100%", minHeight: "60vh", height: "99vh" }}
            src={`${constUrlVisor.ambient}?docId=${fileState?.document?.DocumentData?.Id
              }&rol=${participant ? (participant[0]?.Reviewer ? "Admin" : "User") : ''
              }&fileId=${fileState?.item?.FileData?.Id
              }&sectionId=${fileState?.document?.DocumentData?.Sections[0]?.Id
              }&docName=${fileState?.document?.DocumentData?.Name
              }&user=${userName.trimEnd()
              }&token=${sessionStorage.token}`}
          />
        }
        open={openEditorModal}
        closeAction={!openEditorModal}
      />
    </Fragment >
  );
};

const StageFirma = (props) => {
  const fileState = useSelector((state) => state.File);

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={10} md={10} lg={6} >
          {props.isActiveMassiveSignature === true ?
            <Box sx={{ mt: 1.5 }}>
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                Estatus: <span style={{ color: " #C20E30" }} >Firma de documentos </span>
              </Typography>
            </Box>
            :
            <Box sx={{ mt: 1.5 }}>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                  Documento a firmar
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {props.participantCurso === true ?
                  "El siguiente documento representa la versión final, si estás de acuerdo presiona firmar." :
                  `La solicitud se encuentra en proceso de firma por ${fileState?.item?.FileData?.CurrentUser}`}
              </Typography>
            </Box>
          }
        </Grid>

        <Grid container justifyContent={'center'} item xs={12} md={12} lg={6}>
          <Box>
            {fileState?.document?.OnlyView ===
              true ? null : !props.documentValidationApproved &&
                props.documentStatus === "Firmado" ? (
              <ButtonAceptarRechazoFirma
                setValue={props.setValue}
                Id={fileState.document?.DocumentData?.Id}
              />
            ) : (
              props.stagesDocumentButtons()
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid xs={12} md={12} lg={props.participantCurso === false ? 12 : 7} >
        <Grid container item xs={12} sm={12}>
          {props.isActiveMassiveSignature == true ?
            <Grid item xs={12} sm={12} lg={12} sx={{ border: "1px solid #E4E4E4", p: 1, mt: 1, borderRadius: 2 }} >
              <MassiveFlowDocumentViewer />
            </Grid>
            : <Grid item xs={12} sm={12} lg={12} sx={{ border: "1px solid #E4E4E4", p: 1, mt: 1, borderRadius: 2 }} >
              <Box sx={{ display: { xs: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
                {props.blobUrl ? (
                  <VisorPDF
                    pdfUrl={props.blobUrl ?? ""}
                    pdfName={fileState?.document_64?.FileName ?? ""}
                  />
                ) : (
                  <Spinner />
                )}
              </Box>
              <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                {props.blobUrl ? (
                  <VisorPDF
                    pdfUrl={props.blobUrl ?? ""}
                    pdfName={fileState?.document_64?.FileName ?? ""}
                  />
                ) : (
                  <Spinner />
                )}
              </Box>
            </Grid>}
        </Grid>
      </Grid>
      {props.participantCurso === false ? null : (
        <Grid container justifyContent={'center'} xs={12} md={12} lg={5} sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } }}>
          <Box textAlign="center" sx={{ ml: 3, mt: 7, borderLeft: '2px s  olid #898B8D', p: 3 }}>
            <Typography variant={"h6"}>
              Si lo prefieres, puedes escanear el siguiente código QR desde tu dispositivo móvil para continuar con el flujo.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', mt: 2 }}>
              <img src={props.urlImagen} alt="ImagenQR" style={{ width: '65%' }} />
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

const ButtonFirma = (props) => {
  const dispatch = useDispatch();
  const [LoadingAction, setLoadingAction] = useState(false);
  const stageName = useSelector(
    (state) => state.File.item?.FileData?.Stage?.WorkflowInstance
  );
  const stage = useSelector(
    (state) => state.File.item?.FileData?.Stage
  );
  const stageOtp = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.Properties
  );


  const enviarPIN = async () => {
    setLoadingAction(true);
    const fileFlowService = FileFlowService.getInstance();
    if (stage?.Name === "EvidenciasValidacion" && stageName?.CurrentStatus === "Completo") {
      setLoadingAction(false);
      return props.setValue(2);
    } else if (stage?.Name === "Evidencias" && stageName?.CurrentStatus === "Inicializado") {
      setLoadingAction(false);
      return props.setValue(2);
    } else {
      if (stageName?.CurrentStatus === "Inicializado" || stageName?.CurrentStatus === "Integración de documentos" || stageName?.CurrentStatus === "OTP") {
        const otp = stageOtp.filter((prop) => prop.Name === "EnableOTP");
        if (otp !== undefined && otp !== null && otp[0].Value === "1") {
          try {
            const statusOtp = await fileFlowService.registerStatusStage({
              File_id: props.fileState.item.FileData.Id,
              Status: "OTP",
              Result: null,
              IsMasive: props.isActiveMassiveSignature,
            });
            if (statusOtp.IsOK === true && statusOtp.ResponseCode == 0) {
              setLoadingAction(false);
              return dispatch(
                notificationPINStageFile({
                  Body: {
                    File_Id: props.fileState.item.FileData.Id,
                    IsMasive: props.isActiveMassiveSignature
                  }
                }), props.setValue(2)
              );
            } else {
              setLoadingAction(false);
              ToastNotification({
                icon: "error",
                text: statusOtp.Messages,
              });
            }
          } catch (error) {
            setLoadingAction(false);
            ToastNotification({
              icon: "error",
              text: error.message,
            });
          }
        } else {
          setLoadingAction(false);
          return props.setValue(2);
        }
      } else {
        setLoadingAction(false);
        return props.setValue(2);
      }
    }
  };

  return (
    <Grid container justifyContent={'rigth'}>
      <Grid item xs={12} md={6}>
        <RevisarOtroMomento firma={true} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            p: 1,
            display: "flex",
            justifyItems: "center",
            minWidth: 230,
            justifyContent: "center",
          }}
        >
          <LoadingButton
            loading={LoadingAction}
            fullWidth
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => {
              enviarPIN();
              sessionStorage.removeItem("firmab64");
            }}
          >
            <Typography sx={{ letterSpacing: 1.2 }}>Firmar</Typography>
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

const ButtonAceptarRechazoFirma = (props) => {
  const dispath = useDispatch();

  const aceptarRechazo = () => {
    dispath(
      acceptanceRejection({
        Document_Id: props.Id,
        Comments: "",
      }),
      props.setValue(0)
    );
  };

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: "flex",
          justifyItems: "center",
          width: 254,
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          size="medium"
          variant="outlined"
          color="primary"
          onClick={() => aceptarRechazo()}
        >
          Aprobar Rechazo
        </Button>
      </Box>
    </>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};

// const ButtonCerrarEdicion = () => {
//   const document = useSelector((state) => state.File);
//   const dispath = useDispatch();
//   const [open, setOpen] = useState(false);

//   const cerrarEdicion = () => {
//     dispath(
//       closeDocumentVersion({
//         Document_Id: document?.document?.DocumentData?.Id,
//         Section_Id: document?.document?.DocumentData?.Sections[0]?.Id,
//         Approved: true,
//         Comments: "",
//         ValidateFileComplete: true
//       }), handleClickOpen()
//     );
//   };

//   const handleClickOpen = () => {
//     setOpen(!open);
//   };

//   return (
//     <>
//       <Box
//         sx={{
//           p: 1,
//           display: "flex",
//           justifyItems: "center",
//           width: 254,
//           justifyContent: "center",
//         }}
//       >
//         <Button
//           fullWidth
//           size="medium"
//           variant="contained"
//           color="primary"
//           onClick={() => handleClickOpen()}
//         >
//           <Typography sx={{ letterSpacing: 1.2, fontWeight: 550 }}>
//             Cerrar edición
//           </Typography>
//         </Button>
//       </Box>

//       <Dialog
//         open={open}
//         onClose={handleClickOpen}
//         maxWidth={"xs"}
//         fullWidth
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           <Box sx={{ textAlign: "center" }}>
//             Cerrar Revisión
//           </Box>
//         </DialogTitle>
//         <DialogContent>
//           <Box sx={{ textAlign: "center" }}>
//             <Typography variant="body1">
//               Estas a punto de cerrar la revisión de este docuemento para todos los participantes
//             </Typography>
//           </Box>
//           <Box sx={{ display: "flex", justifyContent: "center", p: 2, mt: 1 }}>
//             <Button
//               size="medium"
//               onClick={handleClickOpen}
//               sx={{
//                 borderTop: 1,
//                 minWidth: 150,
//                 borderColor: "secondary.main40",
//               }}
//             >
//               Cancelar
//             </Button>
//             <Button
//               size="medium"
//               onClick={() => cerrarEdicion()}
//               sx={{
//                 borderTop: 1,
//                 minWidth: 150,
//                 borderLeft: 1,
//                 borderColor: "secondary.main40",
//               }}
//             >
//               Aceptar
//             </Button>
//           </Box>
//         </DialogContent>
//       </Dialog>

//     </>
//   );
// };