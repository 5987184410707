import React, { useEffect, useState } from "react";
import {
  Accordion as Acc,
  AccordionSummary as AccSum,
  AccordionDetails,
  Button,
  Box,
  styled,
  Grid,
  useTheme,
  Typography,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import videoTutorial from "../../assets/Requisitos_Previos_Masivo.mp4"
import iconExcel from "../../assets/DescargaExcelIcon.png";
import plantilla from "../../assets/UploadPrerequisitesTemplate.xlsx";
import LoadingButton from "@mui/lab/LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { ExpandMore } from "@mui/icons-material";
import { UploadGenericControl } from "../../components/UploadGenericControl";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadPrerequisitesBatchFile,
  resetUsersPrevious,
} from "../../store/actions";
import { GenericTable } from "../../components/GenericTable";
import { Icon } from "@iconify/react";
import { InputField } from "../../components/InputField";
import { useFormContext } from "react-hook-form";
import { Alert } from "../../components/Alert";
import { DocumentButton } from "./DocumentButton";
import { MassiveService } from "../../services/MassiveService";
import { CustomModalAlert } from "../../components/CustomModalAlert";
import { FileFlowService } from "../../services/FileFlowService";
import HelpPages from "../../components/HelpPages";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "1px solid red",
  borderBottomColor: theme.palette.secondary.main40,
}));

const Img = styled("img")(({ theme }) => ({
  width: 50,
  [theme.breakpoints.down("sm")]: {
    width: 50,
  },
}));

const columnsUsersPrevious = [
  {
    Header: "Clave Solicitud",
    accessor: "invoice",
  },
  {
    Header: "Correo participante “Carga documentos”",
    accessor: "emailUser",
  },
  {
    Header: "Alias participante “Carga documentos”",
    accessor: "alias",
  },
  {
    Header: "Fecha limite de carga",
    accessor: "validity",
  },
  {
    Header: "Requiere aprobación",
    accessor: "require",
  },
  {
    Header: "Correo participante “Aprobar documentos",
    accessor: "emailAprobador",
  },
  {
    Header: "Alias participante “Aprobar documentos",
    accessor: "aliasAprobador",
  },
  {
    Header: "",
    accessor: "statusUser",
  },
];

export const ConfigReqPreviosMassiveTab = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, formState, setValue } =
    useFormContext();
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const stateDataUsers = useSelector((state) => state.UploadPrerequisitesUsers);
  const [dataPrevious, setDataPrevious] = useState([]);
  const [b65PreviosUsers, setB65PreviosUsers] = useState(null);
  const [alertCancel, setAlertCancel] = useState(false);
  const [expandedUsers, setExpandedUsers] = useState(true);
  const [expandedListDocuments, setExpandedListDocuments] = useState(false);
  const [validationUsers, setValidationUsers] = useState(true);
  const [loadignDocButton, setLoadignDocButton] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [listDocuments, setListDocuments] = useState([]);
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const watchDocumentoObligatorio = watch("documentoObligatorio");
  const watchDocumentName = watch("documentName");

  const massiveService = MassiveService.getInstance();

  useEffect(() => {
    if (stateDataUsers?.items) {
      const filesTable = stateDataUsers.items?.Response?.Result.map((file) => {
        return {
          invoice: file.ClaveSolicitud,
          emailUser: file?.LoadUser_Email,
          alias: file.LoadUser_Alias,
          validity: file.LoadUser_DeathLine,
          require: file.RequiresApproval,
          emailAprobador: file?.ApprovalUser_Email,
          aliasAprobador: file.ApprovalUser_Alias,
          statusUser:
            file.State === true ? (
              <Icon
                icon="el:ok-circle"
                fontSize={25}
                style={{
                  mb: "-1px",
                  mr: 1,
                  fontSize: 25,
                  color: "#0FA958",
                }}
              />
            ) : (
              <Tooltip title={file.ErrorToolTip} placement="top">
                <Icon
                  icon="zondicons:close-outline"
                  fontSize={25}
                  style={{
                    mb: "-1px",
                    mr: 1,
                    fontSize: 25,
                    color: "#D65E74",
                  }}
                />
              </Tooltip>
            ),
        };
      });
      const someUser = stateDataUsers.items?.Response?.Result.some(
        (objeto) => objeto.State === false
      );
      console.log("someUser", someUser);
      setValidationUsers(someUser);
      console.log("filesTable", filesTable);
      setDataPrevious(filesTable);
      setLoadingPrevious(false);
    } else if (stateDataUsers?.error) {
      setB65PreviosUsers(null);
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        text: `Ocurrió un error. ${stateDataUsers?.error}.`,
      });
      setLoadingPrevious(false);
      setDataPrevious([]);
    }
  }, [stateDataUsers.items, stateDataUsers?.error]);

  const uploadDataParticipants = (file, base64, extension, process) => {
    try {
      if (base64 !== null && base64 !== undefined && base64 !== "") {
        setLoadingPrevious(true);
        dispatch(
          UploadPrerequisitesBatchFile({
            File_Id: folioIdSolicitud,
            FileContentB64: base64,
          })
        );
      }
    } catch (error) {
      setLoadingPrevious(false);
      console.log("error", error);
    }
  };

  console.log("dataPrevious", dataPrevious);

  const handleAddDocument = async () => {
    setLoadignDocButton(true);
    if (
      watchDocumentName === undefined ||
      watchDocumentName === null ||
      watchDocumentName === ""
    ) {
      return;
    }
    let nameDoc =
      watchDocumentName === undefined ||
      watchDocumentName === null ||
      watchDocumentName === ""
        ? "Documento Default"
        : watchDocumentName;
    let idDocument =
      listDocuments.length > 0
        ? parseInt(listDocuments[listDocuments.length - 1].Id) + 1
        : listDocuments?.length + 1;
    let requiredCheck =
      watchDocumentoObligatorio === undefined ||
      watchDocumentoObligatorio === null ||
      watchDocumentoObligatorio === ""
        ? false
        : watchDocumentoObligatorio;

    const existeObjeto = listDocuments.some(
      (objeto) => objeto.DocumentName === nameDoc
    );

    if (existeObjeto) {
        Alert({
        icon: "error",
        title: "Error",
        text: "Ya existe un documento con ese nombre",
      })
      setLoadignDocButton(false)
    }

    try {
      const response = await massiveService.RegisterPrerequisitesDocument({
        BatchUploadPrerequisitesDocument_Id: "",
        File_Id: folioIdSolicitud,
        DocumentName: nameDoc,
        Required: requiredCheck,
      });
      console.log("responseDoc", response);

      let addItem = {
        Id: idDocument,
        idResponse: response.Body.Response.BatchUploadPrerequisitesDocument_Id,
        DocResponseId: idDocument,
        DocumentConfiguration: {
          FileName: nameDoc,
        },
        DocumentName: nameDoc,
        Required: requiredCheck,
      };

      // listDocuments.push(addItem);
      const newListDocuments = [...listDocuments, addItem];
      setListDocuments(newListDocuments);
      setValue("documentName", "");
      setValue("documentoObligatorio", true);
      setLoadignDocButton(false);
    } catch (error) {
      setLoadignDocButton(false);
      Alert({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };

  const handleRemoveDocument = async (e) => {
    try {
      await massiveService.DisablePrerequisitesDocument({
        BatchUploadPrerequisitesDocument_Id: e.idResponse,
      });
      let lista = listDocuments.filter(
        (item) => item.DocumentName !== e.DocumentName
      );
      setListDocuments(lista);
    } catch (error) {
      Alert({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    setValue("documentoObligatorio", true);
  }, []);

  const omitirPrevious = async () => {
    try {
      const response = await massiveService.SkipPrerequisites({
        File_Id: folioIdSolicitud,
      });
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: folioIdSolicitud,
        Status: "Carga de documentos",
        Result: null,
      });
      dispatch(resetUsersPrevious());
      props.handleNextStep();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const sendNextStep = async () => {
    try {
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: folioIdSolicitud,
        Status: "Carga de documentos",
        Result: null,
      });
      props.handleNextStep();
      dispatch(resetUsersPrevious());
    } catch (error) {
      Alert({
        icon: "error",
        title: "Error",
        text: error.message,
      }); 
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <RootStyle>
        <HelpPages
          Body={
            <Box sx={{ mt: 2 }}>
              <video
                width={"100%"}
                className="HelpVideo"
                src={videoTutorial}
                autoPlay
                loop
                muted
              />
            </Box>
          }
        />
        <Accordion
          disableGutters
          expanded={expandedUsers}
          defaultExpanded={expandedUsers}
        >
          <AccordionSummary
            onClick={() => setExpandedUsers(!expandedUsers)}
            expandIcon={<ExpandMore />}
            sx={{
              mt: 1,
              p: 0,
              "& .MuiAccordionSummary-content": {
                m: 0,
              },
            }}
          >
            <Typography variant="titulo">
              Participantes responsables de cargar los requisitos previos
              <Tip
                title="Este usuario será el encargado de cargar los documentos solicitados por
                  el usuario administrador."
                placement="top"
                arrow
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon sx={{ ml: 1, fontSize: 23 }} />
              </Tip>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle"
                  sx={{ fontWeight: 400, mt: 2, mb: 0.8 }}
                >
                  {dataPrevious?.length > 0
                    ? "Descarga el formato de carga y captura los participantes que serán responsables de la carga de los documentos a solicitar, se creará una solicitud por cada renglón capturado en el excel."
                    : "Verifica que la información sea correcta, si existe algún error deberás modificarlo y cargar nuevamente el archivo presionando el botón cargar archivo."}
                </Typography>
              </Box>
              {dataPrevious?.length > 0 ? null : (
                <Box sx={{ p: 2 }}>
                  <Tooltip title="Descargar el template" placement="top">
                    <a
                      href={plantilla}
                      target="_blank"
                      rel="noopener noreferrer"
                      download="Plantilla requisitos previos.xlsx"
                    >
                      <Img src={iconExcel} alt="iconExcel" />
                    </a>
                  </Tooltip>
                </Box>
              )}
            </Box>
            {dataPrevious?.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                <GenericTable
                  Columns={columnsUsersPrevious}
                  Data={dataPrevious}
                  TotalRows={dataPrevious?.length}
                />
              </Box>
            ) : (
              <Box sx={{ mt: 3 }}>
                {loadingPrevious === true ? (
                  <Spinner />
                ) : (
                  <UploadGenericControl
                    Accept=".xlsx"
                    onChange={(file, base64, extension) => {
                      console.log("base64", base64);
                      if (
                        base64 === null &&
                        base64 === "" &&
                        base64 === undefined
                      ) {
                        return null;
                      } else {
                        setB65PreviosUsers(base64);
                        uploadDataParticipants(
                          file,
                          base64,
                          extension,
                          "Revisión"
                        );
                      }
                    }}
                  />
                )}
              </Box>
            )}

            <Grid container spacing={2} justifyContent="center">
              {dataPrevious?.length > 0 ? (
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    sx={{ height: 45, px: 10 }}
                    onClick={() => {
                      dispatch(resetUsersPrevious());
                      setDataPrevious([]);
                      setB65PreviosUsers(null);
                    }}
                  >
                    Cargar archivo
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    sx={{ height: 45, px: 10 }}
                    onClick={() => setAlertCancel(!alertCancel)}
                  >
                    Omitir
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <Box>
                  <LoadingButton
                    fullWidth
                    disabled={validationUsers}
                    variant="contained"
                    sx={{ height: 45, px: 0 }}
                    onClick={() => {
                      setExpandedListDocuments(true);
                      setExpandedUsers(false);
                    }}
                  >
                    Enviar
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          expanded={expandedListDocuments}
          defaultExpanded={expandedListDocuments}
        >
          <AccordionSummary
            onClick={() => setExpandedListDocuments(!expandedListDocuments)}
            expandIcon={<ExpandMore />}
            sx={{
              mt: 1,
              p: 0,
              "& .MuiAccordionSummary-content": {
                m: 0,
              },
            }}
          >
            <Typography variant="titulo">Documentos a cargar</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle"
                  sx={{ fontWeight: 400, mt: 2, mb: 0.8 }}
                >
                  Ingresa el nombre de los documentos a solicitar a los
                  responsables de carga de documentos, los documentos
                  configurados serán solicitados para todas las solicitudes
                  creadas
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 1 }}>
              <InputField
                inputType="text"
                control={control}
                label="Nombre de documento"
                name="documentName"
                placeholder="Ingresa el nombre del documento"
                // validations={{ required: true }}
                error={!!formState.errors["documentName"]}
              />
              <InputField
                inputType="check"
                control={control}
                name="documentoObligatorio"
                label="Documento obligatorio"
                tooltipText=""
                defaultValue={false}
              />
              {Object.keys(formState.errors).length > 0 && (
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{ display: "block", mt: 1 }}
                >
                  Campos requeridos*
                </Typography>
              )}
              <LoadingButton
                variant="contained"
                disabled={
                  watchDocumentName?.length === 0 ||
                  watchDocumentName === undefined
                }
                fullWidth={xsScreen}
                loading={loadignDocButton}
                sx={{
                  mt: 4,
                  mb: 10,
                  height: 45,
                  marginRight: "auto",
                  display: "block",
                  maxWidth: 380,
                }}
                onClick={handleSubmit(handleAddDocument)}
              >
                Añadir documento
              </LoadingButton>

              {listDocuments?.length > 0 && (
                <Box
                  sx={(theme) => ({
                    boxShadow: theme.shadows[9],
                    mt: 2,
                    mb: 3,
                  })}
                >
                  {listDocuments?.map((doc, i) => (
                    <DocumentButton
                      listDocs={false}
                      key={doc.Id}
                      doc={doc}
                      index={i + 1}
                      HandleRemoveList={() => handleRemoveDocument(doc)}
                    />
                  ))}
                </Box>
              )}
            </Box>

            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  sx={{ height: 45, px: 10 }}
                  onClick={() => setAlertCancel(!alertCancel)}
                >
                  Omitir
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box>
                  <LoadingButton
                    fullWidth
                    disabled={listDocuments?.length > 0 ? false : true}
                    variant="contained"
                    sx={{ height: 45, px: 0 }}
                    onClick={() => sendNextStep()}
                  >
                    Enviar
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </RootStyle>

      <CustomModalAlert
        Open={alertCancel}
        Title="¿Está seguro que desea omitir este paso?"
        SubTitle="Si omites este paso, ya no podrás ingresar documentos previos a tu proceso de revisión y/o firmado"
        LeftButtonText="Aceptar"
        LeftButtonAction={() => omitirPrevious()}
        RightButtonText="Cancelar"
        RightButtonAction={() => {
          setAlertCancel(false);
        }}
      />
    </LocalizationProvider>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};
