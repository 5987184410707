import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
  IconButton,
  tooltipClasses,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";

import revisor from "../../assets/icons/Revisor.png";
import aprobadorRevisor from "../../assets/icons/AprobadorRevision.png";

import { Circle } from "@mui/icons-material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { FileFlowService } from "../../services/FileFlowService";
import { useSelector } from "react-redux";
import { ToastNotification } from "../../components/toastNotification";
import BackdropComponent from "../../components/BackDrop";
import { makeStyles } from "@mui/styles";
import IconToolTip from "../../components/IconToolTip";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 5,
  marginBottom: 1,
  borderBottom: "1px solid #D9D9D9",
  display: "flex",
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "60%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "60%",
  },
}));

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
    "& .MuiTableCell-root": {
      border: `.9px solid ${theme.palette.primary.main20}`,
    },
  },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.primary.TableHeader,
    fontWeight: 500,
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCellsSing = [
  {
    id: "FullName",
    numeric: false,
    disablePadding: true,
    label: "Nombre del participante",
  },
  {
    id: "funcion",
    numeric: true,
    disablePadding: false,
    label: "Función",
  },
  {
    id: "SignDeadlineFormat",
    numeric: true,
    disablePadding: false,
    label: "Fecha límite",
  },
  {
    id: "SignStatus",
    numeric: true,
    disablePadding: false,
    label: "Estatus",
  },
  {
    id: "Mail",
    numeric: true,
    disablePadding: false,
    label: "Reenvío de Correo Electrónico",
  },
];

const headCellsCheck = [
  {
    id: "FullName",
    numeric: false,
    disablePadding: true,
    label: "Nombre del participante",
  },
  {
    id: "funcion",
    numeric: true,
    disablePadding: false,
    label: "Función",
  },
  {
    id: "CheckDeadlineFormat",
    numeric: true,
    disablePadding: false,
    label: "Fecha límite",
  },
  {
    id: "CheckStatus",
    numeric: true,
    disablePadding: false,
    label: "Estatus",
  },
  {
    id: "Mail",
    numeric: true,
    disablePadding: false,
    label: "Reenvío de Correo Electrónico",
  },
];

function EnhancedTableHead(props) {
  const typeDate = (type) => {
    if (type === "sing") {
      return "Firmado";
    } else if (type === "admin") {
      return "Creación del Expediente";
    } else if (type === "Captura") {
      return "Captura de información";
    } else {
      return "Revisión";
    }
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell1
          colSpan={props.type === "sing" ? 6 : 5}
          align={"center"}
        >
          <Typography variant="subtitle" sx={{ fontWeight: 500 }}>
            {props.finally ? (
              <b>Expediente Finalizado</b>
            ) : (
              <b>{typeDate(props.type)}</b>
            )}
          </Typography>
        </StyledTableCell1>
      </TableRow>
      <TableRow>
        {props.type === "sing"
          ? headCellsSing.map((headCell) => (
              <StyledTableCell1
                key={headCell.id}
                align={headCell.numeric ? "center" : "left"}
              >
                <b>{headCell.label}</b>
              </StyledTableCell1>
            ))
          : headCellsCheck.map((headCell) => (
              <StyledTableCell1
                key={headCell.id}
                align={headCell.numeric ? "center" : "left"}
              >
                {headCell.id === "Mail" ? (
                  <>
                    <Tip
                      title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <b>{headCell.label}</b>
                    </Tip>
                  </>
                ) : (
                  <b>{headCell.label}</b>
                )}
              </StyledTableCell1>
            ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ParticipantSortTable(props) {
  const classes = useStyles();
  const fileState = useSelector((state) => state.File);
  const userLogin = useSelector((state) => state.Authentication?.items);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [loading, setLoading] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("calories");

  const checkDocumentUsers = useSelector((state) => {
    const documents = state.File.item?.Documents;
    if (documents) {
      const documentWithStatus = documents.find(
        (document) => document.participantStatusCheck.length > 0
      );
      if (documentWithStatus) {
        return documentWithStatus.participantStatusCheck;
      }
    }
    return null; // O cualquier valor predeterminado que desees usar cuando no se encuentre un documento válido
  });

  const typoSing = useSelector(
    (state) =>
      state.NewFile?.file?.fileForm?.FileConfiguration?.SignatureOptions
  );

  const typeSignFile = useSelector(
    (state) => state.File?.item?.SignatureOptions
  );

  useEffect(() => {
    setRows(props.listRows);
  }, [props.listRows]);

  const sendMailUser = async (id) => {
    try {
      setLoading(true);
      const fileService = FileFlowService.getInstance();
      if (fileState?.item?.FileData?.Status === "Finalizado") {
        const responseSendMail =
          await fileService.NotificationReenvioFinalizacionFirma({
            Body: {
              file_Id: fileState?.item?.FileData?.Id,
              user_Id: id,
              token: sessionStorage.token,
            },
          });
        if (responseSendMail.Body) {
          ToastNotification({
            icon: "success",
            text: responseSendMail?.Body?.message,
          });
          setLoading(false);
        }
      } else {
        const responseSendMail = await fileService.SendMailNotification({
          Body: {
            file_Id: fileState?.item?.FileData?.Id,
            user_Id: id,
          },
        });
        if (responseSendMail.Body) {
          ToastNotification({
            icon: "success",
            text: responseSendMail?.Body?.message,
          });
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      ToastNotification({
        icon: "error",
        text: error?.message,
      });
    }
  };

  const validacion = (user) => {
    let userParticipant = fileState?.item?.Participants.find(
      (item) => item.Email == userLogin?.UserLoginData?.Email
    );
    if (
      userParticipant != undefined ||
      fileState?.item?.FileData.CreationUser === userLogin?.UserLoginData?.Email
    ) {
      console.log("entro aqui");
      if (fileState?.item?.FileData?.Status === "Finalizado cancelado") {
        return true;
      } else if (fileState?.item?.FileData?.Status === "Finalizado") {
        return false;
      } else {
        if (
          fileState?.item?.FileData?.StageName === "Revisión de documentos" &&
          props.type === "sing"
        ) {
          return true;
        } else if (
          fileState?.item?.FileData?.StageName === "Firma de documentos"
        ) {
          return fileState?.item?.FileData?.CurrentUser === user.Email
            ? false
            : true;
        }
      }
    } else {
      return true;
    }
  };

  const userStatusCheck = (user) => {
    let userCheck = checkDocumentUsers?.find(
      (item) => item.Username === user.Email
    );
    return userCheck?.Status;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <BackdropComponent loading={loading} />
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead type={props.type} finally={props.finally} />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.index}>
                      <StyledTableCell1 component="th" scope="row" align="left">
                        {row.FullName}
                      </StyledTableCell1>

                      {props.type === "check" ? (
                        <>
                          <StyledTableCell1 align="left">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {row.Reviewer === true ? (
                                <Tip
                                  title="Usuario Aprobador."
                                  placement="top"
                                  enterTouchDelay={0}
                                >
                                  <img
                                    style={{ width: 30 }}
                                    alt="imagenLogo"
                                    src={aprobadorRevisor}
                                  />
                                </Tip>
                              ) : row.Check === true ? (
                                <Tip
                                  title="Usuario Editor."
                                  placement="top"
                                  enterTouchDelay={0}
                                >
                                  <img
                                    style={{ width: 30 }}
                                    alt="imagenLogo"
                                    src={revisor}
                                  />
                                </Tip>
                              ) : null}
                            </Box>
                          </StyledTableCell1>
                          <StyledTableCell1 align="center">
                            {row.Check === true
                              ? row.CheckDeadlineFormat
                              : row.ReviewerDeadlineFormat ||
                                row.CheckDeadlineFormat}
                          </StyledTableCell1>
                          <StyledTableCell1 align="center">
                            {/* TABLECHECKS */}
                            <IconSemaforoCheck Status={userStatusCheck(row)} />
                            {row.Reviewer === true &&
                            row.IsCheckedAllDocuments === false ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Circle
                                  sx={{ mb: "-1px", mr: 1 }}
                                  fontSize="small"
                                  style={{ color: "#C5C8CD" }}
                                />
                                Pendiente Aprobación
                              </Box>
                            ) : row.Reviewer === true &&
                              row.IsCheckedAllDocuments === true ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Circle
                                  sx={{ mb: "-1px", mr: 1 }}
                                  fontSize="small"
                                  style={{ color: "#3E8F41" }}
                                />
                                Aprobado
                              </Box>
                            ) : fileState?.item?.FileData.StageName ===
                                "Finalizado cancelado" ||
                              fileState?.item?.FileData.StageName ===
                                "Resumen" ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "right",
                                }}
                              >
                                <Circle
                                  sx={{ mb: "-1px", mr: 1 }}
                                  fontSize="small"
                                  style={{ color: "#DA2F2F" }}
                                />
                                Rechazado
                              </Box>
                            ) : null}
                          </StyledTableCell1>
                        </>
                      ) : props.type === "Captura" ? (
                        <>
                          <StyledTableCell1 align="left">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <IconToolTip
                                src={"CaptureIcon.png"}
                                title={"Usuario Capturador."}
                                width={23}
                              />
                            </Box>
                          </StyledTableCell1>
                          <StyledTableCell1 align="center">
                            {row.CaptureDeadlineFormat}
                          </StyledTableCell1>
                          <StyledTableCell1 align="center">
                            <IconSemaforoCheck Status={row.CaptureStatus} />
                          </StyledTableCell1>
                        </>
                      ) : (
                        <>
                          <StyledTableCell1>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {props.type === "admin" ? (
                                "Administrador"
                              ) : row.SignatureOptions ? (
                                row.SignatureOptions.SignatureType ===
                                "Simple" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log("AQUI 3")}

                                    <IconToolTip
                                      icon={true}
                                      src={"teenyicons:otp-outline"}
                                      title={"Contraseña de un solo uso."}
                                      width={21}
                                    />
                                    <IconToolTip
                                      src={"SignSimple.png"}
                                      title={
                                        "Firma Autógrafa en Mensaje de Datos."
                                      }
                                      width={26}
                                    />
                                    <IconToolTip
                                      src={
                                        row?.SignatureOptions?.VideoRecording
                                          ? "VideoIcon.svg"
                                          : "VideoIconDisabled.svg"
                                      }
                                      title={
                                        "Videograbación de consentimiento."
                                      }
                                      width={26}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log("AQUI 4")}
                                    <IconToolTip
                                      icon={true}
                                      iconColor={
                                        row?.SignatureOptions?.OTP
                                          ? "#D65E74"
                                          : "#979797"
                                      }
                                      src={"teenyicons:otp-outline"}
                                      title={"Contraseña de un solo uso."}
                                      width={21}
                                    />
                                    <IconToolTip
                                      src={"SignAvanzada.png"}
                                      title={"Firma Electrónica Avanzada."}
                                      width={26}
                                    />
                                    <IconToolTip
                                      src={
                                        row?.SignatureOptions?.VideoRecording
                                          ? "VideoIcon.svg"
                                          : "VideoIconDisabled.svg"
                                      }
                                      title={
                                        "Videograbación de consentimiento."
                                      }
                                      width={26}
                                    />
                                  </Box>
                                )
                              ) : row.Sign ? (
                                typoSing?.SignatureType === "Simple" ||
                                typeSignFile?.SignatureType === "Simple" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log("AQUI 1")}
                                    <IconToolTip
                                      icon={true}
                                      src={"teenyicons:otp-outline"}
                                      title={"Contraseña de un solo uso."}
                                      width={21}
                                    />
                                    <IconToolTip
                                      src={"SignSimple.png"}
                                      title={
                                        "Firma Autógrafa en Mensaje de Datos."
                                      }
                                      width={26}
                                    />
                                    <IconToolTip
                                      src={
                                        typeSignFile?.VideoRecording ||
                                        typoSing?.VideoRecording
                                          ? "VideoIcon.svg"
                                          : "VideoIconDisabled.svg"
                                      }
                                      title={
                                        "Videograbación de consentimiento."
                                      }
                                      width={26}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {console.log("AQUI 2")}
                                    <IconToolTip
                                      icon={true}
                                      src={"teenyicons:otp-outline"}
                                      title={"Contraseña de un solo uso."}
                                      width={21}
                                      iconColor={
                                        typeSignFile?.OTP || typoSing?.OTP
                                          ? "#D65E74"
                                          : "#979797"
                                      }
                                    />
                                    <IconToolTip
                                      src={"SignAvanzada.png"}
                                      title={"Firma Electrónica Avanzada."}
                                      width={26}
                                    />
                                    <IconToolTip
                                      src={
                                        typeSignFile?.VideoRecording ||
                                        typoSing?.VideoRecording
                                          ? "VideoIcon.svg"
                                          : "VideoIconDisabled.svg"
                                      }
                                      title={
                                        "Videograbación de consentimiento."
                                      }
                                      width={26}
                                    />
                                  </Box>
                                )
                              ) : null}
                            </Box>
                          </StyledTableCell1>
                          <StyledTableCell1 align="center">
                            {row.SignDeadlineFormat}
                          </StyledTableCell1>
                          <StyledTableCell1 align="center">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {props.type === "admin" ? (
                                "En resguardo"
                              ) : (
                                <>
                                  <IconSemaforo
                                    Status={row.IsSignedAllDocuments}
                                  />
                                  {row.IsSignedAllDocuments === true &&
                                  row.SignStatus === "Atendido en tiempo"
                                    ? "Firmado"
                                    : "Sin Firmar"}
                                </>
                              )}
                            </Box>
                          </StyledTableCell1>
                        </>
                      )}
                      <StyledTableCell1 align="center">
                        <Tip
                          title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
                          placement="top"
                          enterTouchDelay={0}
                        >
                          <spam>
                            <IconButton
                              onClick={() => sendMailUser(row.UserId)}
                              disabled={validacion(row)}
                              sx={{ color: "#000000" }}
                            >
                              <ForwardToInboxIcon />
                            </IconButton>
                          </spam>
                        </Tip>
                      </StyledTableCell1>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        {props.listRows.map((item, index) => (
          <CardResponsiveFile
            key={index + 1}
            data={item}
            type={props.type}
            sendMailUser={sendMailUser}
            validacion={validacion}
            userStatusCheck={userStatusCheck}
          />
        ))}
      </Box>
    </Box>
  );
}

const CardResponsiveFile = (props) => {
  const fileState = useSelector((state) => state.File);

  const typeDate = (type) => {
    if (type === "sing") {
      return "Firmado";
    } else if (type === "admin") {
      return "Creación del Expediente";
    } else if (type === "Captura") {
      return "Captura de información";
    } else if (type === "check") {
      return "Revisión";
    } else {
      if (props.data.Reviewer === true) {
        return "Usuario Aprobador";
      } else {
        return "Usuario Editor";
      }
    }
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          p: 1,
          m: 0.8,
          border: 1.5,
          borderColor: "#D9D9D9",
          borderRadius: 3,
        }}
        onClick={() => props.fileFlow(props.type === "admin")}
      >
        <Box>
          <Typography variant="h6">{typeDate(props.type)}</Typography>
        </Box>
        <Statements
          title={"Nombre del participante"}
          text={props.data.FullName}
        />
        <Statements
          title={"Fecha límite"}
          text={
            props.type === "sing"
              ? props.data.SignDeadlineFormat
              : props.type === "admin"
              ? props.data.SignDeadlineFormat
              : props.data.CheckDeadlineFormat
          }
        />
        <Statements
          title={"Estatus"}
          text={
            props.type === "sing" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <IconSemaforo Status={props.data.IsSignedAllDocuments} />
                {props.data.IsSignedAllDocuments === true
                  ? "Firmado"
                  : "Sin Firmar"}
              </Box>
            ) : props.type === "admin" ? (
              "En resguardo"
            ) : (
              <>
                <IconSemaforoCheck Status={props.userStatusCheck(props.data)} />
                {props.data.Reviewer === true &&
                props.data.IsCheckedAllDocuments === false ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <Circle
                      sx={{ mb: "-1px", mr: 1 }}
                      fontSize="small"
                      style={{ color: "#C5C8CD" }}
                    />
                    Pendiente Aprobación
                  </Box>
                ) : props.data.Reviewer === true &&
                  props.data.IsCheckedAllDocuments === true ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <Circle
                      sx={{ mb: "-1px", mr: 1 }}
                      fontSize="small"
                      style={{ color: "#3E8F41" }}
                    />
                    Aprobado
                  </Box>
                ) : fileState?.item?.FileData.StageName ===
                    "Finalizado cancelado" ||
                  fileState?.item?.FileData.StageName === "Resumen" ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                    }}
                  >
                    <Circle
                      sx={{ mb: "-1px", mr: 1 }}
                      fontSize="small"
                      style={{ color: "#DA2F2F" }}
                    />
                    Rechazado
                  </Box>
                ) : null}
              </>
            )
          }
        />
        <Statements
          title={"Reenvíar notificación"}
          text={
            <Tip
              title="Se reenviará al correo del usuario la notificación correspondiente al proceso del expediente."
              placement="top"
              enterTouchDelay={0}
            >
              <IconButton
                onClick={() => props.sendMailUser(props.data.UserId)}
                disabled={props.validacion(props.data)}
                sx={{ color: "#000000" }}
              >
                <ForwardToInboxIcon />
              </IconButton>
            </Tip>
          }
        />
      </Box>
    </Grid>
  );
};

const Statements = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="subtitle1" textAlign={"right"}>
          {props.text}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};

const IconSemaforo = (props) => {
  return (
    <>
      {props.Status === true ? (
        <Circle
          sx={{ mb: "-1px", mr: 1 }}
          fontSize="small"
          style={{ color: "#3E8F41" }}
        />
      ) : props.Status === false ? (
        <Circle
          sx={{ mb: "-1px", mr: 1 }}
          fontSize="small"
          style={{ color: "#C5C8CD" }}
        />
      ) : null}
    </>
  );
};

const IconSemaforoCheck = (props) => {
  if (!props.Status) {
    return null;
  }

  return (
    <>
      {props.Status === "Documento editado" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#DA2F2F" }}
          />
          Modificado
        </Box>
      ) : props.Status === "Sin asignar" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#C5C8CD" }}
          />
          {props.Status}
        </Box>
      ) : props.Status === "Asignado en tiempo" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#3E8F41" }}
          />
          {props.Status}
        </Box>
      ) : props.Status === "Atendido en tiempo" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#3E8F41" }}
          />
          {props.Status}
        </Box>
      ) : props.Status === "Sin Revisar" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#D9D9D9" }}
          />
          Sin abrir
        </Box>
      ) : props.Status === "Revisado" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#FADA36" }}
          />
          Abierto
        </Box>
      ) : props.Status === "Abierto" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#FADA36" }}
          />
          Abierto
        </Box>
      ) : props.Status === "Validado" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "right", md: "center" },
            alignItems: "center",
          }}
        >
          <Circle
            sx={{ mb: "-1px", mr: 1 }}
            fontSize="small"
            style={{ color: "#3E8F41" }}
          />
          Aprobado
        </Box>
      ) : null}
    </>
  );
};
