import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import Timer from "./Timer";
import ControlButtons from "./ControlButtons";

const useStyles = makeStyles((theme) => ({
    stopWatch: {
        // height: '85vh',
        width: '23vw',
        // =]=backgroundColor: '#0d0c1b',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}));

export const StopWatch = (props) => {
    const classes = useStyles();

    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(0);

    useEffect(() => {
        if (props.Recording) {
            setIsActive(true);
            setIsPaused(false);
        }
    }, [props.Recording]);

    useEffect(() => {
        if (!props.Recording) {
            setIsActive(false);
            setTime(0);
        }
    }, [props.Stopped]);

    useEffect(() => {
        let interval = null;
        if (isActive && isPaused === false) {
            interval = setInterval(() => {
                setTime((time) => time + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused]);

    useEffect(() => {
        if (props.MaxLengthRecording !== undefined && props.MaxLengthRecording !==null 
            && props.MaxLengthRecording!=="") {
                let timerCalculate = Math.floor((time / 1000)/*  % 60 */)
                // console.log("timerCalculate: ", timerCalculate," - props.MaxLengthRecording ", props.MaxLengthRecording )
        if (timerCalculate >  parseInt(props.MaxLengthRecording -1)) {
           props.setStopRecording(true)
        }            
    } 
    }, [time,props.MaxLengthRecording]);

    


    // const handleStart = () => {
    //     setIsActive(true);
    //     setIsPaused(false);
    // };

    // const handlePauseResume = () => {
    //     setIsPaused(!isPaused);
    // };

    // const handleReset = () => {
    //     setIsActive(false);
    //     setTime(0);
    // };

    return (
        <div className={classes.stopWatch}>
            <Timer time={time} />
            {/* <ControlButtons
                active={isActive}
                isPaused={isPaused}
                handleStart={handleStart}
                handlePauseResume={handlePauseResume}
                handleReset={handleReset}
            /> */}
        </div>
    );
}